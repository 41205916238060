import React from "react"
import Layout from "../components/layouts"
import { Hero, Breadcrumb, ContactForm } from "../components/slices"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

export const query = graphql`
  query ContactQuery {
    allContentfulContactPage {
      nodes {
        title
        subtext {
          raw
        }
        seo {
          title
          description
        }
      }
    }
  }
`

const ContactPage = ({ data }) => {
  const { subtext, seo } = data.allContentfulContactPage.nodes[0]

  const breadcrumbData = [
    { title: "Home", _meta: "/" },
    { title: "Contact", _meta: "/contact" },
  ]
  return (
    <Layout>
      <Helmet>
        <title>{seo.title ? seo.title : "Solicom"}</title>
        <meta
          name="description"
          content={seo.description ? seo.description : "Solicom"}
        />
      </Helmet>
      <Hero
        data={data.allContentfulContactPage.nodes[0]}
        className="-compact"
      />
      <div className="container -pinched">
        <Breadcrumb data={breadcrumbData} />
      </div>
      <div className="content">
        <div className="[ container -pinched ]">
          {subtext && renderRichText(subtext)}
          <ContactForm />
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
